/* eslint-disable */
/**
 * Created by KELLY-PC on 14/01/2019.
 */

export default {
// VALIDAR CAMPOS
  validaCampo(campo) {
    if (campo !== undefined && campo !== null && campo !== '') {
      return true
    } else {
      return false
    }
  },

// COMPARAR VALORES
  retorno(item, str, retorno1, retorno2) {
    if (typeof str === 'boolean') {
      if (item === str) {
        return retorno1
      } else {
        return retorno2
      }
    } else {
      if (item.toLowerCase() === str.toLowerCase()) {
        return retorno1
      } else {
        return retorno2
      }
    }


  },

// VERIFICAR SE É NUMERO
  IsNumeric(str) {
    str = str.replace(',', '.')
    return !!str.match(/^-?\d*\.?\d+$/)
  },

// VERIFICAR SE É LETRA
  IsLetter(str) {
    return !!str.match(/^[a-zA-Z]/)
  },

// VERIFICAR SE O NUMERO É INTEIRO
  IsInteiro(str) {
    return !!str.match(/^\d+$/)
  },


  voltarTelaAnterior(caminho, tempo) {
    setInterval(function () {
      window.location = caminho
    }, tempo)
  },

  desabilitarAdicionar(lista) {
    if (lista.length <= 0) {
      return false
    } else {
      return true
    }
  },


// ADICIONAR MASCARAS
  maskDecimal(v) {
    v = v.toString()
    if (v !== null) {
      v = v.replace(/\D/g, '')
      v = v.replace(/(\d{2})$/, '0,$1')
      v = v.replace(/(\d+)(\d{3},\d{2})$/g, '$1.$2')
      var qtdLoop = (v.length - 3) / 3
      var count = 0
      while (qtdLoop > count) {
        count++
        v = v.replace(/(\d+)(\d{3}.*)/, '$1.$2')
      }
      v = v.replace(/^(0)(\d)/g, '$2')
    }
    return v
  },

  maskCpf (cpf) {
    // eslint-disable-next-line no-useless-escape,no-useless-escape, no-useless-escape, no-useless-escape
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '\$1.\$2.\$3\-\$4')
  },

  maskCep (cpf) {
    // eslint-disable-next-line no-useless-escape,no-useless-escape, no-useless-escape, no-useless-escape
    return cpf.replace(/(\d{2})(\d{3})(\d{3})/g, '\$1.\$2\-\$3')
  },

  maskTel (cpf) {
    // eslint-disable-next-line no-useless-escape,no-useless-escape, no-useless-escape, no-useless-escape
    return cpf.replace(/(\d{2,3})(\d{4,5})(\d{4})/g, '(\$1)\$2\-\$3')
  },

  maskCnpj (cnpj) {
    // eslint-disable-next-line no-useless-escape,no-useless-escape, no-useless-escape, , no-useless-escape, no-useless-escape
    return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '\$1.\$2.\$3\/\$4\-\$5')
  },

  TestaCPF (strCPF) {
    var CPF = require('cpf_cnpj').CPF
    var CNPJ = require('cpf_cnpj').CNPJ
    var isValido
    if (this.validaCampo(strCPF)) {
      strCPF = strCPF.replace(/[^0-9]+/g, '')

      if (strCPF.length === 11) {
        isValido = CPF.isValid(strCPF)
      } else if (strCPF.length >= 12 && strCPF.length <= 14) {
        isValido = CNPJ.isValid(strCPF)
      } else {
        isValido = false
      }
    }
    return isValido
  },

  // REMOVER MASCARAS */
  removerMascara (str) {
    // if (str !== null && str !== undefined) {
    //   str = str.replace(/[^0-9]+/g, '')
    // }
    // return str
    if (this.validaCampo(str)) {
      return str.replace(/[^0-9]+/g, '')
    } else {
      return ''
    }
  },

  removeMascaraMonetaria (v) {
    var a
    var b
    if (v.length >= 10) {
      a = v.replace('.', '')
      a = a.replace('.', '')
      b = a.replace(',', '.')
    } else {
      a = v.replace('.', '')
      b = a.replace(',', '.')
    }
    return b
  },

  // FORMATAR CAMPOS
  formatMonetarioBR(v) {
    v = v.toString()

    if (v !== null) {
      v = v.replace(/\D/g, '')
      v = v.replace(/(\d{2})$/, ',$1')
      v = v.replace(/(\d+)(\d{3},\d{2})$/g, '$1.$2')
      var qtdLoop = (v.length - 3) / 3
      var count = 0
      while (qtdLoop > count) {
        count++
        v = v.replace(/(\d+)(\d{3}.*)/, '$1.$2')
      }
      v = v.replace(/^(0)(\d)/g, '$2')
    }

    return v
  },

  /* Formatação dos marcadores */

  toUppercase(str) {
    str = str.replace(/\s/g, '').toUpperCase()
    if (str.length !== 3) return false
    return str
  },

  formatName(str) {
    let words = str.split(' ').filter(str => str !== '')
    words = words.map(str => str.normalize('NFD').replace(/[\u0300-\u036f]/g, ''))
    words = words.map(str => str[0].toUpperCase() + str.slice(1))
    return words.join(' ')
  },

  LetraMaiuscula(text) {
    text = text.toLowerCase().replace(/(?:^|\s)\S/g, function (a) {
      return a.toUpperCase()
    })
    return text
  },

  paraDecimal(v) {
    var decimal = v.replace(',', '.')
    return decimal
  },

  maskData(data) {
    return data.replace(/^(\d{1,2})(\d{1,2})(\d{4})$/, '$1/$2/$3')
  },

  inverterData(data, rem, add) {
    var newData = data.split(rem)
    return newData.reverse().join(add)
  },

  dataSql(data) {
    data = this.maskData(data)
    data = this.inverterData(data, '/', '-')
    return data
  },

  IsCEP(strCEP) {
    var aux = strCEP.replace(/^([0-9]{2})([0-9]{3})([0-9]{3})$/, '$1.$2-$3')
    return aux
  },

  // Chips
  dropDownShow() {
    var openDropdown = document.getElementById('myDropdown')
    if (openDropdown.classList.contains('close')) {
      openDropdown.classList.remove('close')
    }
    openDropdown.classList.toggle('show')
  },

  dropDownClose() {
    var openDropdown = document.getElementById('myDropdown')
    if (openDropdown.classList.contains('show')) {
      openDropdown.classList.remove('show')
    }
    openDropdown.classList.toggle('close')
  },

  /*   ORDENAR    */
  ordenarLista(lista) {
    lista = lista.sort(function (a, b) {
      return a.descricao < b.descricao ? -1 : a.descricao > b.descricao ? 1 : 0
    })
    return lista
  },

  /*  ADCIONAR E REMOVER CLASSE CSS */
  addClassById(id, classe) {
    var elemento = document.getElementById(id)
    var classes = elemento.className.split(' ')
    var getIndex = classes.indexOf(classe)
    if (getIndex === -1) {
      classes.push(classe)
      elemento.className = classes.join(' ')
    }
  },

  delClassById(id, classe) {
    var elemento = document.getElementById(id)
    var classes = elemento.className.split(' ')
    var getIndex = classes.indexOf(classe);

    if (getIndex > -1) {
      classes.splice(getIndex, 1)
    }
    elemento.className = classes.join(' ')
  },

  addClassByClasse(classe, novaClasse) {
    var elemento = document.getElementsByClassName(classe)
    var classes = elemento.className.split(' ')
    var getIndex = classes.indexOf(novaClasse)
    if (getIndex === -1) {
      classes.push(novaClasse)
      elemento.className = classes.join(' ')
    }
  },

  delClassByClasse(classe, novaClasse) {
    var elemento = document.getElementsByClassName(classe)
    var classes = elemento.className.split(' ')
    var getIndex = classes.indexOf(novaClasse);

    if (getIndex > -1) {
      classes.splice(getIndex, 1)
    }
    elemento.className = classes.join(' ')
  },

  alterarBackgroundTabela(classe, cor) {
    var elemento = document.getElementsByClassName(classe)
    if (this.validaCampo(elemento)) {
      for (var i = 0; i <= elemento.length; i++) {
        if (this.validaCampo(elemento[i])) {
          if (elemento[i].className === classe) {
            elemento[i].style.background = cor
          }
        }

      }
    }
  },

  /* STRNGS  */
  cortarString(str, tam) {
    return str.slice(0, tam);
  },

  /* Força da senha */
  verificaForca(senha) {
    // senha = document.getElementById("senha").value;
    let forca = 0

    // mostra = document.getElementById("mostra");
    if (senha.length >= 1 && senha.length <= 6) {
      forca += 10
    } else if (senha.length >= 7 && senha.length <= 10) {
      forca += 20
    } else if (senha.length >= 11 && senha.length <= 15) {
      forca += 25
    } else if (senha.length >= 16 && senha.length <= 20) {
      forca += 30
    } else if (senha.length >= 21) {
      forca += 35
    }
    if (senha.match(/[a-z]+/)) {
      forca += 10
    }
    if (senha.match(/[A-Z]+/)) {
      forca += 10
    }
    if (senha.match(/d+/)) {
      forca += 5
    }
    if (senha.match(/[!#$%&'()*+,-./:;?@[\\\]_`{|}~]/)) {
      forca += 20
    }

    return forca
  },

  mostra_res(forca) {
    if (forca < 25) {
      return 'Fraca'
    } else if ((forca >= 25) && (forca < 50)) {
      return 'Boa'
    } else if ((forca >= 50) && (forca < 75)) {
      return 'Forte'
    } else {
      return 'Excelente'
    }
  },

  mostra_cor(forca) {
    if (forca < 25) {
      // return 'error'
      return '#EF5350'
    } else if ((forca >= 25) && (forca < 50)) {
      // return 'warning'
      return '#FF8F00'
    } else if ((forca >= 50) && (forca < 75)) {
      // return 'light-green'
      return '#8BC34A'
    } else {
      // return 'green darken-4'
      return '#1B5E20'
    }
  },

  focusInput(id) {
    var loadTime = 10
    window.onload = function () {
      loadTime = window.performance.timing.domContentLoadedEventEnd - window.performance.timing.navigationStart;
    }
    var interval = setInterval(function () {
      var muniImpt = document.getElementById(id)
      if (muniImpt !== null) {
        muniImpt.focus()
      }

      clearInterval(interval)
    }, loadTime)
  },

  chunkArray(myArray, n) {
    let tempArray = []
    let chunk_size = Math.ceil(Math.max(myArray.length / n, 1))
    for (let index = 0; index < myArray.length; index += chunk_size) {
      tempArray.push(myArray.slice(index, index + chunk_size))
    }
    return tempArray
  },
  //
  // ordenar (array) {
  //   let posicao = 1;
  //   let index = 0;
  //   let teste = [];
  //   let j;
  //   for (j of array) {
  //     if (index > 0) {
  //       if (Number(j.pontuacao) < Number(array[index - 1].pontuacao)) {
  //         posicao++
  //       }
  //     }
  //     index++
  //     j.posicao = posicao
  //     teste.push(j)
  //   }
  //   return teste
  // },

  irPara (path) {
    window.location = path
  },
  sortearPosicaoArray (array) {
    let sorteada = Math.floor(Math.random() * array.length)
    return array[sorteada]
  },
  reload (id) {
    let container = document.getElementById(id)
    container.click()
  },
  mudarFundo (id, cor) {
    let item = document.getElementById(id)
    item.style.backgroundColor = cor
  },

  mudarCorList (cor) {
    let listHover = document.getElementById('listHover')
    listHover.style.color = cor
  },
  mobileHeight() {
    var height = window.screen.height
    var res = height - (height * 0.3)
    return parseInt(res)
  },
  gera_id(size) {
    var randomized = Math.ceil(Math.random() * Math.pow(10, size));
    var digito = Math.ceil(Math.log(randomized));
    let r = randomized.toString(36)
    var n = r.replace('0.', '')
    return n + digito
  },
}
