<template>
  <v-app id="inspireInicio">
    <router-view/>
  </v-app>
</template>

<script>
/* eslint-disable */
  import img from '../src/assets/img/background/fundo (4).jpg'

  export default {
    mounted() {
      var inspireInicio = document.getElementById('inspireInicio')
      inspireInicio.style.backgroundImage = "url('" + img + "')"
      inspireInicio.style.backgroundRepeat = 'no-repeat'
      inspireInicio.style.backgroundSize= '100% 100%'
    }
  }
</script>

<style lang="scss">
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }

  #nav {
    padding: 30px;

    a {
      font-weight: bold;
      color: #2c3e50;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }
</style>
