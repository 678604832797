/* eslint-disable */
import { lista, processar } from '../../services/GenericAPI'
import { EventBus } from '../../helpers/event-bus'
import {Configuracao} from "../../models/configuracao";


const url = '/configuracao'

const state = {
  all: []
}

const getters = {
  listaConfiguracaoSemFiltro: (state) => {
    return state.all.length > 0 ? state.all[0] : new Configuracao()
  },
  pegaConfiguracaoId: (state) => (id) => {
    let mar = state.all.find(configuracao => configuracao.configuracao_id === id)
    return mar !== null ? mar.descricao : '** NÃO ENCONTRADA'
  }
}

const actions = {
  async setAllConfiguracao ({ commit }) {
    const list = (await lista(url)).data
    commit('SET_CONFIGURACOES', list)
  },
  async processarConfiguracao ({ commit }, { configuracao, acao }) {
    try {
      let res = await processar(url, configuracao, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      this.dispatch('configuracao/setAllConfiguracao')
      EventBus.$emit('PROCESSO_CONCLUIDO', true, true, 'Termo')
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data)
      EventBus.$emit('PROCESSO_CONCLUIDO', false, true, 'Termo')
    }
  },
  setConfiguracao ({ commit }, configuracao) {
    commit('SET_CONFIGURACAO', configuracao)
  }
}

const mutations = {
  SET_CONFIGURACOES (state, configuracao) {
    state.all = configuracao
  },
  SET_CONFIGURACAO (state, configuracao) {
    state.configuracao = configuracao
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
