/* eslint-disable */
import {lista, processar, pegaLista} from '../../services/GenericAPI'
import {Filtro, Join, Left, Where} from "../../models/filtro/filtro";
import {InstagramSCH, getBaseSCH} from "../../search/InstagramSCH"
import {EventBus} from '../../helpers/event-bus'

import {Instagram} from '../../models/instagram'
import _ from 'lodash'

const url = '/instagram'

const state = {
  all: [],
  allLista: [],
  filtro: new Filtro()
}

const getters = {

  listaInstagram: (state) => {
    return state.all
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  searchItem({commit}, search) {
    state.filtro.filters.addFilter('descricao' ,
      "%"+search+"%",
      'like'
    )
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', InstagramSCH(perPage))
  },

  async setAllInstagram({commit}) {
    try {
      const list = (await pegaLista(url, state.filtro)).data
      commit('SET_INSTAGRAM', list)
      EventBus.$emit('LISTA_PROCESSADA', true)
    } catch (error) {
      EventBus.$emit('LISTA_PROCESSADA', false)
    }

  },
  async getToken({commit}) {
    try {
      var urls = "https://graph.instagram.com/access_token?grant_type=ig_exchange_token&client_secret={instagram-app-secret}&access_token={short-lived-access-token}"

      const list = (await lista(urls)).data
      console.log(list);
      // commit('SET_INSTAGRAM', list)
    } catch (error) {
    }

  },

  async processarInstagram({commit}, {instagram, acao}) {
    try {
      let res = await processar(url, instagram, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      this.dispatch('instagram/setAllInstagram')
      EventBus.$emit('INSTA_PROCESSO_CONCLUIDO', true)
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data)
      EventBus.$emit('INSTA_PROCESSO_CONCLUIDO', false)
    }
  }
}

const mutations = {
  SET_INSTAGRAM(state, instagram) {
    state.all = instagram
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
