<template>
  <div>
    <v-dialog v-model="Mostrar" :width="width" persistent>
      <v-card>
        <v-card-title class="headline" primary-title id="modalhead" style="background: black; color: white">
          {{getTitulo()}}
        </v-card-title>
        <v-card-text>
          <slot></slot>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable */
  import util from '../assets/js/Util'

  export default {
    props: {
      titulo: {
        default: ''
      },
      modal: {
        default: false
      },
      width: {
        default: '50%'
      }
    },
    name: "ModalCadastro",
    data() {
      return {util}
    },
    computed: {
      Mostrar: {
        get() {
          return this.modal
        },
        set() {
          return this.Mostrar
        }
      },
    },

    methods: {
      getTitulo() {
        if (util.validaCampo(this.titulo)) {
          return this.titulo
        } else {
          return this.$router.currentRoute.meta
        }
      }
    },
    mounted() {
      // let modalhead = document.getElementById('modalhead')
      // modalhead.style.color = '#000'
      // let sidebar = document.getElementById('sidebar')
      // let stringExemplo = sidebar.style.backgroundColor
      // stringExemplo = stringExemplo.replace(/, 0\.6/g , "")
      // if (util.validaCampo(this.$store.state.sidebarColor)) {
      //   let cor = this.$store.state.sidebarColor
      //   cor.setAlpha(1);
      //   modalhead.style.backgroundColor = stringExemplo
      //   modalhead.style.color = '#fff'
      // } else {
      //   modalhead.style.color = '#fff'
      //   modalhead.style.backgroundColor = "#1f1f1f"
      // }
    }
  }
</script>

<style scoped>

</style>
