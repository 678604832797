/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class BannerImagem {
  constructor (banner_id) {
    this.banner_imagem_id = uuidV4();
    this.banner_id = banner_id
    this.imagens = [];
    this.url_video = '';
    this.is_video = false;
    this.efeito = '';
    this.height = 100;
    this.width = 100;
    this.alin_vertical = 10;
    this.alin_horizontal = 10;
  }
}
