<template>
  <!--<div>-->
    <v-card >
      <v-card-title class="headline cabecalho-modal" primary-title>
        <small> {{titulo}} Imagem</small>
      </v-card-title>

      <v-card-text style="padding-top: 0px;padding-bottom: 0px;">
        <v-alert :value="alertClose" color="error" icon="warning" outlined dismissible>
          {{mensagem}}
        </v-alert>

        <div v-show="vcropperProgress" class="text-xs-center">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>
        <div :class="classe" id="divBack">
          <vue-cropper :style="{display: display} + 'height: 400px'" ref='cropper' :guides="true" :view-mode="1"
                       drag-mode="crop" :auto-crop-area="1" :background="true" :rotatable="true" :auto-crop="true"
                       :img="imgUrl" :src="imgSrc" :aspectRatio="aspRatio" id="imageCropper" :img-style="estiloCropper">
          </vue-cropper>
        </div>
      </v-card-text>
      <v-card-actions style="padding-top: 0px;padding-bottom: 0px;">
        <v-spacer></v-spacer>
        <div v-if="isSvg">
          <v-btn @click.native="SalvarSvg()">
            <!--<v-icon>close</v-icon>-->
            Confirmar
          </v-btn>
          <v-btn @click.native="Fechar()">
            <v-icon>close</v-icon>
            Cancelar
          </v-btn>

        </div>

        <div v-else>
          <v-radio-group v-model="checkbox" row>
            <v-radio label="Recomendado" :value="ratio" v-if="ratio !== ''"></v-radio>
            <v-radio label="16/9" value="16/9" v-show="_16_9"></v-radio>
            <v-radio label="4/3" value="4/3" v-show="_4_3"></v-radio>
            <v-radio label="livre" value="Livre" v-show="livre"></v-radio>
          </v-radio-group>

          <v-btn @click.native="cropImage" :loading="spinner">
            <v-icon>crop</v-icon>
            Cortar
          </v-btn>
          <v-btn @click.native="Fechar()">
            <v-icon>close</v-icon>
            Cancelar
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  <!--</div>-->
</template>

<script>
/* eslint-disable */
  //  import VueCropper from 'vue-cropperjs'
  export default {
    name: "cropper-imagem",
    // components: {
    //   VueCropper: () => import('vue-cropperjs')
    // },
    data: () => ({
      estiloCropper: {'width': '100%', 'height': '350px'},
      aspRatio: 16 / 9,
      rodar: 5,
      vcropperProgress: false,
      mantemExtensao: false,
      alertClose: false,
      isSvg:false,
      spinner: false,
      checkbox: '16/9',
      radioGroup: 1,
      estilo: null,
      informacao: null,
      mensagem: '',
      imgSrc: '',
      imgUrl: '',
      imgName: '',
      cropImg: '',
      tipoImg: '',
      titulo: '',
      display: 'none'
    }),

    props: {
      ratio: {
        default: ''
      },
      classe: {
        default: ''
      },
      imgURL: {
        default: ''
      },
      imagemId: {
        default: ''
      },
      _16_9: {
        default: false
      },
      _4_3: {
        default: false
      },
      livre: {
        default: false
      },
      // isSvg: {
      //   default: false
      // }
    },
    methods: {
      alerta(mensagem, estilo) {
        var _this = this
        this.alertClose = true
        this.mensagem = mensagem
        this.estilo = estilo
        var itervalo = setTimeout(function () {
          _this.alertClose = false
          clearInterval(itervalo)
        }, 3000)
      },
      SalvarSvg() {
        this.spinner = true
        if (this.imgSrc === '' || this.imgSrc === null) {
          this.alerta('SELECIONE UMA IMAGEM', 'erro')
          this.spinner = false
        } else {
          this.$emit('cropperSvg', this.imgSrc, this.mantemExtensao)
        }
        this.$refs.cropper.destroy()
        this.$refs.cropper.clear()
      },
      limpar() {
        var imageCropper = document.getElementById('imageCropper')
        var divBack = document.getElementById('divBack')
        divBack.classList.remove('vcropperBackground2');
        divBack.classList.add('vcropperBackground');
        imageCropper.style.display = 'none'
      },
      Fechar() {
        this.$refs.cropper.destroy()
        this.$refs.cropper.clear()
        this.display = 'none'
        this.limpar()
        this.$emit('showCropper', false)
      },
      TrocarClasse() {
        var imageCropper = document.getElementById('imageCropper')
        var divBack = document.getElementById('divBack')
        var classes = divBack.className.split(' ')

        var getIndex
        classes.splice(getIndex, 1)

        let tela = window.screen.availWidth

        if (tela <= 360) {
          this.estiloCropper = {'width': '100%', 'height': '200px'}
          getIndex = classes.indexOf('vcropperBackground2300')
          if (getIndex === -1) {
            classes.push('vcropperBackground2300')
            divBack.className = classes.join(' ')
          }
        } else {
          this.estiloCropper = {'width': '100%', 'height': '500px'}
          getIndex = classes.indexOf('vcropperBackground2')
          if (getIndex === -1) {
            classes.push('vcropperBackground2')
            divBack.className = classes.join(' ')
          }
        }
        imageCropper.style.display = 'block'
      },
      setImage(e, mantemExtensao) {
        this.mantemExtensao = mantemExtensao
        this.TrocarClasse()
        this.$refs.cropper.initCrop()
        // const file = e.target.files[0]
        const file = e.files[0]
        // const file = e.files

        if (!file.type.includes('image/')) {
          this.alerta('SELECIONE UMA IMAGEM', 'erro')
          this.spinner = false
          return
        }

        this.isSvg = file.type.includes('image/svg+xml')

        if (typeof FileReader === 'function') {
          const reader = new FileReader()
          reader.onload = (event) => {
            this.imgSrc = event.target.result
            this.imgUrl = this.imgSrc
            this.tipoImg = file.type
            this.imgName = file.name
            this.$refs.cropper.replace(event.target.result)
          }
          reader.readAsDataURL(file)
        } else {
          this.alerta('SELECIONE UMA IMAGEM', 'erro')
          this.spinner = false
          this.vcropperProgress = false;
        }
      },
      cropImage() {
        console.log('cropImage')
        this.spinner = true
        if (this.imgSrc === '' || this.imgSrc === null) {
          this.alerta('SELECIONE UMA IMAGEM', 'erro')
          this.spinner = false
        } else {
          this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL(this.tipoImg)
          if (this.tipoImg === '') {
            this.tipoImg = this.base64MimeType(this.cropImg)
            var extencao = this.tipoImg.replace('image/', '')
            this.imgName = 'IMG.' + extencao
          }
          let atualizou
          if (this.imgURL != null) {
            atualizou = true
          } else {
            atualizou = false
          }
          this.$emit('vcropper', this.cropImg, this.mantemExtensao)
        }
        this.$refs.cropper.destroy()
        this.$refs.cropper.clear()
      },
      base64MimeType(encoded) {
        var result = null
        if (typeof encoded !== 'string') {
          return result
        }
        var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/)
        if (mime && mime.length) {
          result = mime[1]
        }
        return result
      }
    },
    mounted () {
      if (this.ratio !== '') {
        // console.log('ratio W: ', this.ratio)
        // console.log('ratio W: ', JSON.parse(this.ratio))
        // this.aspRatio = JSON.parse(this.ratio)
        this.checkbox = this.ratio
      } else {
        this.checkbox = 'livre'
      }

      // if (this.ratio !== '') {
      //   this.$refs.cropper.setAspectRatio(this.ratio)
      // }
    },
    watch: {
      'checkbox': function () {
        if (this.checkbox === '3/4') {
          this.aspRatio = 3 / 4
          this.$refs.cropper.setAspectRatio(this.aspRatio)
        } else if (this.checkbox === '4/3') {
          this.aspRatio = 4 / 3
          this.$refs.cropper.setAspectRatio(this.aspRatio)
        } else if (this.checkbox === '16/9') {
          this.aspRatio = 16 / 9
          this.$refs.cropper.setAspectRatio(this.aspRatio)
        } else if (this.checkbox === '9/16') {
          this.aspRatio = 9 / 16
          this.$refs.cropper.setAspectRatio(this.aspRatio)
        } else if (this.checkbox === 'livre') {
          this.aspRatio = null
          this.$refs.cropper.setAspectRatio(this.aspRatio)
        } else {
          this.$refs.cropper.setAspectRatio(0)
        }
      }
    },
  }
</script>

<style scoped>
  @import '../assets/css/CropperCss.css';

  .vcropperBackground2 {
    background: url('../assets/img/vcropperBackground.png') repeat-x;
    width: 100%;
    height: 500px;
    border: 1px gray solid;
  }

  .vcropperBackground2300 {
    background: url('../assets/img/vcropperBackground.png') repeat-x;
    width: 100%;
    height: 200px;
    border: 1px gray solid;
  }

  .vcropperBackground {
    width: 100%;
    height: 400px;
    /*background-image: url('../../../static/img/vcropperBackground.png');*/
    /*background-repeat: repeat-x;*/
    background: transparent;
    border: 1px gray solid;
  }

  .vcropperBackground300 {
    width: 100%;
    height: 200px;
    background: transparent;
    border: 1px gray solid;
  }

  .listaImagem {
    width: 200px;
    height: 150px;
    border: 1px solid gray;
  }
</style>

