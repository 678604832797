<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <v-navigation-drawer width="260px" left class="testeHover" id="sidebar" ref="sideDrawer" stateless value="true" dark
                         persistent :mini-variant="miniVariant" :clipped="clipped" v-model="drawer"
                         enable-resize-watchers app>
      <v-list>
        <v-list-item>
          <v-list-item-action @click.stop="abreFechaSidebar()" id="icnClick2">
            <v-btn text icon>
              <v-icon class="listHoverIcon">dehaze</v-icon>
            </v-btn>
          </v-list-item-action>
          <v-list-item-title class="title">{{ $route.meta }}</v-list-item-title>
        </v-list-item>
      </v-list>

      <v-list style="background: transparent" id="listHover" class="listHover">
        <v-list>
          <v-list-item to="/principal" @click="irPara('principal')">
            <v-list-item-action>
              <v-icon class="listHoverIcon">home</v-icon>
            </v-list-item-action>
            <v-list-item-title class="navmenu" style="font-size: 1.2rem; ">HOME</v-list-item-title>
          </v-list-item>
        </v-list>

        <!--CADASTRO-->
        <v-list-group prepend-icon="list" no-action dark>
          <template v-slot:activator>
            <v-list-item>
              <v-list-item-title style="text-transform: uppercase">Cadastros</v-list-item-title>
            </v-list-item>
          </template>
          <v-list-item class="navmenu" v-for="cadastro in cadastros" :key="cadastro.title" :to="cadastro.path"
                       @click="irPara(cadastro.path)">
            <v-card class="dropnav" flat style="background: transparent" v-show="miniVariant">
              <v-list-item-title>
                {{ cadastro.title.substr(0, 3).toUpperCase() }}
              </v-list-item-title>
            </v-card>

            <v-list-item-content>
              <v-list-item-title  :id="cadastro.path">{{ cadastro.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <!--CONFIGURAÇÕES-->
        <v-list-group prepend-icon="settings" no-action>
          <template v-slot:activator>
            <v-list-item>
              <v-list-item-title style="text-transform: uppercase">Configuração</v-list-item-title>
            </v-list-item>
          </template>
          <v-list-item class="navmenu" v-for="configuracao in configuracao" :key="configuracao.title"
                       @click="irPara(configuracao.path)" :to="configuracao.path">

            <v-card class="dropnav" flat style="background: transparent"
                    v-show="miniVariant">
              <v-list-item-title>
                {{ configuracao.title.substr(0, 3).toUpperCase() }}
              </v-list-item-title>
            </v-card>

            <v-list-item-content>
              <v-list-item-title :id="configuracao.path">{{ configuracao.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list style="text-align: left">
          <v-list-item @click="sair()">
            <v-list-item-action>
              <v-icon class="listHoverIcon">exit_to_app</v-icon>
            </v-list-item-action>
            <v-list-item-title class="navmenu">Sair</v-list-item-title>
          </v-list-item>
        </v-list>

      </v-list>
    </v-navigation-drawer>

    <!--<v-app-bar id="toobarDash" :clipped-left="clipped" app dark>-->
      <!--<v-app-bar-nav-icon v-show="bntTop" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>-->
      <!--<v-toolbar-title v-show="bntTop">{{ $route.meta }}</v-toolbar-title>-->
      <!--&lt;!&ndash;SEARCH&ndash;&gt;-->
      <!--<div style="width: 50%" v-show="toolbarsearch">-->
        <!--<v-text-field-->
          <!--v-model="model"-->
          <!--:search-input.sync="pesquisa"-->
          <!--label="Pesquisar"-->
          <!--@change="Pesquisar()"-->
          <!--class="hidden-sm-and-down inp "-->
          <!--flat solo-inverted-->
          <!--prepend-inner-icon="search"-->
          <!--:append-icon="showFilter ? 'fa fa-filter' : ''" @click:append="abreModalFiltro()"-->
        <!--&gt;</v-text-field>-->
      <!--</div>-->
      <!--<v-spacer></v-spacer>-->

      <!--<v-btn dark icon v-show="toolbarsave" @click="executaCancelBtn">-->
        <!--<v-icon>close</v-icon>-->
      <!--</v-btn>-->
      <!--<v-btn dark icon v-show="toolbarsave" :loading="show_progress" @click="executaSaveBtn">-->
        <!--<v-icon>check</v-icon>-->
      <!--</v-btn>-->

      <!--<v-app-bar-nav-icon @click.stop="trocaIncon(drawerRight)" v-show="!drawerRight">-->
        <!--<v-icon>{{ icon }}</v-icon>-->
      <!--</v-app-bar-nav-icon>-->
    <!--</v-app-bar>-->


    <!-- Parte que exibir o conteudo central do cadastro -->
    <v-main fluid id="conteudo">
      <v-container fluid style="position: relative !important;">
        <router-view/>
        <br>
        <div class="text-align-rigth" style="color: white">
          <!--tempo em minutos-->
          <pro-timer tempo="1440" texto="Sua sessão expira em: "></pro-timer>
        </div>
      </v-container>
    </v-main>

    <!--<v-navigation-drawer  :clipped="clipped" clipped-right dark fixed v-model="drawerRight" right enable-resize-watchers-->
                         <!--stateless persistent app id="sidebarRight" width="60px" >-->
      <!--<v-list style="background: transparent" class="drawbtn">-->
        <!--<v-btn icon @click.stop="trocaIncon(drawerRight)">-->
          <!--<v-icon>{{ icon }}</v-icon>-->
        <!--</v-btn>-->
      <!--</v-list>-->

      <!--<div class="drawrightbtn" v-show="btnAdd">-->
        <!--<v-btn small fab color="green darken-2" @click="executaAddBtn">-->
          <!--<v-icon>add</v-icon>-->
        <!--</v-btn>-->
      <!--</div>-->
    <!--</v-navigation-drawer>-->

    <!-- Dialog que vai exibir a lista de erros caso aja uma validação -->
    <v-dialog v-model="dialogError" max-width="500">
      <v-card v-if="objMsgError !== null">
        <v-card-title class="headline">{{ objMsgError.message }}</v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <div v-if="objMsgError.details !== null">
            <ul :key="index" v-for="(message, index) in objMsgError.details">
              <li> {{ message.mensagem }}</li>
            </ul>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="toggleDialogError()">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Popup que vai exibir mensagens de sucesso -->
    <v-snackbar v-model="showSnack" top color="success" :timeout="2000">
      {{ msgSuccess }}
      <v-btn small color="white" text @click="toggleSnackSuccess">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
/* eslint-disable */
import util from '../assets/js/Util'
import {EventBus} from '../helpers/event-bus'
import router from '../router'
import {mapActions, mapState} from 'vuex'
import miscImageCropper from '../helpers/img-cropper'
import miscMixin from '../helpers/misc'

export default {
  mixins: [miscMixin, miscImageCropper],
  data() {
    return {
      pesquisa: null,
      model: null,
      bntTop: false,
      show_progress: false,
      drawerRight: true,
      icon: 'keyboard_arrow_left',
      search: '',
      clipped: false,
      drawer: true,
      fixed: false,

      bntTop2: true,
      drawerR: true,
      title: 'Titulo',
      principal: [{
        path: '/principal',
        title: 'Principal',
        icon: 'home'
      }],

      configuracao: [
        // {path: '/tema', title: 'Tema', icon: 'home'},
        {
          path: '/usuario',
          title: 'Usuários',
          icon: 'home'
        },
        {
          path: '/sobre',
          title: 'Sobre',
          icon: 'add_shopping_cart'
        },
      ],
      cadastros: [
        {
          path: '/doenca',
          title: 'Doenças',
          icon: 'home'
        },
        {
          path: '/blog',
          title: 'Blog',
          icon: 'home'
        },
        {
          path: '/galeria',
          title: 'Local de Atendimento',
          icon: 'home'
        },
        // {
        //   path: '/url_instagram',
        //   title: 'Instagram',
        //   icon: 'instagram'
        // },
        {
          path: '/banner',
          title: 'Banners',
          icon: 'image'
        },
      ],
      miniVariant2: false,
      right: null
    }
  },
  name: 'Dash',
  methods: {
    ...mapActions('geral', ['setMiniVariant', 'setShowFilter', 'toggleMiniVariant', 'setSidebarColor', 'setSidebarID', 'setModalFiltro',
      'setModal', 'toggleModalFiltro', 'setSearch', 'toggleDialogError', 'toggleSnackSuccess','toggleClipped']),
    sair() {
      sessionStorage.clear()
      router.push({path: '/'}).catch(err => {
      })
    },
    irPara(id) {
      this.setSidebarID(id)
      this.setModalFiltro(false)
      this.search = ''
      this.setSearch('')
      let tinycolor = require('tinycolor2')
      let color
      let c
      if (util.validaCampo(this.sidebarColor)) {
        if (this.sidebarColor === '#000000') {
          c = '#1d94ff'
        } else {
          color = this.sidebarColor
          var colors = tinycolor(color).triad()
          colors = colors.map(function(t) {
            return t.toHexString()
          })
          c = colors[1]
          c = tinycolor(c).darken().toString()
        }
      } else {
        c = '#1d94ff'
      }

      let teste = document.getElementsByClassName('testeHover')
      let listtile = teste[0].getElementsByTagName('div')
      for (let i = 0; i < listtile.length; i++) {
        if (util.validaCampo(listtile[i].id)) {
          if (listtile[i].id.toString() !== id.toString()) {
            listtile[i].style.color = 'white'
          } else {
            listtile[i].style.color = c
          }
        }
      }
    },
    cadastrar() {
      this.setModal(true)
    },
    trocaIncon(drawerRight) {
      this.drawerRight = !this.drawerRight
      if (drawerRight) {
        this.icon = 'keyboard_arrow_right'
      } else {
        this.icon = 'keyboard_arrow_left'
      }
    },
    abreModalFiltro() {
      this.toggleModalFiltro()

      const sectionQuadra = document.getElementById('sectionQuadra')
      const sectionLote = document.getElementById('sectionLote')
      const sectionEventoCaixa = document.getElementById('sectionEventoCaixa')

      if (sectionQuadra !== null) {
        sectionQuadra.style.animation = ''
        if (this.modalfiltro) {
          sectionQuadra.style.animation = 'fadeInDown2 0.5s linear'
        }
      }
      if (sectionLote !== null) {
        sectionLote.style.animation = ''
        if (this.modalfiltro) {
          sectionLote.style.animation = 'fadeInDown2 0.5s linear'
        }
      }
      if (sectionEventoCaixa !== null) {
        sectionEventoCaixa.style.animation = ''
        if (this.modalfiltro) {
          sectionEventoCaixa.style.animation = 'fadeInDown2 0.5s linear'
        }
      }
    },
    abreFechaSidebar() {
      this.toggleMiniVariant()
      this.toggleClipped()
    },
    executaAddBtn(event) {
      EventBus.$emit('CLICK_BTN_ADD')
    },
    executaSaveBtn(event) {
      this.show_progress = true
      EventBus.$emit('CLICK_BTN_SAVE')
    },
    executaCancelBtn(event) {
      EventBus.$emit('CLICK_BTN_CANCEL')
    },
    Pesquisar() {
      // console.log('model: ', this.model)
      this.setSearch(this.model)
    },
  },
  watch: {
    // 'search': function () {
    //   this.setSearch(this.search)
    // },
    'model': function() {
      if (!this.validarCampo(this.model)) {
        this.setSearch('')
      }
    },
    pesquisa: function(val) {
      if (this.validarCampo(val)) {
        this.setSearch(val)
      }
    },
  },
  mounted() {
    let _this = this
    let item = document.getElementById('sidebar')
    let sidebarRight = document.getElementById('sidebarRight')
    let toobarDash = document.getElementById('toobarDash')
    toobarDash.style.backgroundColor = this.sidebarColor
    item.style.backgroundColor = this.sidebarColor
    sidebarRight.style.backgroundColor = this.sidebarColor

    function func() {
      if (_this.miniVariant2) {
        _this.setMiniVariant(false)
      }
    }

    function func1() {
      if (_this.miniVariant2) {
        _this.setMiniVariant(true)
      }
    }

    let icnClick2 = document.getElementById('icnClick2')
    icnClick2.addEventListener('click', function(event) {
      if (!_this.miniVariant2) {
        _this.miniVariant2 = true
        item.addEventListener('mouseover', func, false)
        item.addEventListener('mouseout', func1, false)
      } else {
        _this.miniVariant2 = false
        _this.setMiniVariant(false)
      }
    }, false)


    EventBus.$on('PROCESSO_CONCLUIDO', (sucesso, fecha, path) => {
      this.show_progress = false
      if (sucesso && fecha) {
        router.push({name: path}).catch(err => {
        })
      }
    })
  },
  computed: {
    ...mapState('geral', {
      toolbarsave: 'toolbarsave',
      toolbarsearch: 'toolbarsearch',
      btnAdd: 'btnAdd',
      miniVariant: 'miniVariant',
      sidebarColor: 'sidebarColor',
      modalFiltro: 'modalFiltro',
      toolbarDash: 'toolbarDash',
      dialogError: 'dialogError',
      objMsgError: 'objMsgError',
      snackSuccess: 'snackSuccess',
      showFilter: 'showFilter',
      msgSuccess: 'msgSuccess'
    }),
    showSnack: {
      get() {
        return this.snackSuccess
      },
      set() {
        this.toggleSnackSuccess()
      }
    }
  },
  beforeDestroy() {
    EventBus.$off('CLICK_BTN_ADD')
    EventBus.$off('TEMA_CONCLUIDO')
    EventBus.$off('CLICK_BTN_SAVE')
    EventBus.$off('CLICK_BTN_CANCEL')
    EventBus.$off('PROCESSO_CONCLUIDO')
  }
}
</script>

<style lang="scss">
.v-navigation-drawer .navmenu {
  padding-left: 56px !important;
}

.v-navigation-drawer .v-list-item .v-btn__content {
  padding-left: 0 !important;
  margin-left: 0 !important;
}

.v-navigation-drawer .v-list-item__action {
  margin-right: 15px !important;
  padding-right: 0 !important;
}

.v-navigation-drawer .v-list-item__subtitle {
  padding-left: 16px !important;
  font-size: 8pt !important;
}

.v-navigation-drawer .v-list-item__title {
  margin-left: 0 !important;
  padding-left: 0 !important;
  padding-top: 5px;
  padding-bottom: 5px;
}

.v-navigation-drawer .v-list-item__content {
  margin-left: 0 !important;
  padding-left: 0 !important;
}

.v-navigation-drawer .v-list-item__icon {
  margin-right: 0 !important;
  padding-right: 0 !important;
  justify-content: center !important;
  align-self: center;
}

</style>
