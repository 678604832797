import { render, staticRenderFns } from "./CropperImagem.vue?vue&type=template&id=461e3b62&scoped=true"
import script from "./CropperImagem.vue?vue&type=script&lang=js"
export * from "./CropperImagem.vue?vue&type=script&lang=js"
import style0 from "./CropperImagem.vue?vue&type=style&index=0&id=461e3b62&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "461e3b62",
  null
  
)

export default component.exports