<template>
  <div>
    <pro-alerta :texto="info" :cor="cor" :show="mostrar" @abriAlerta="fecharAlerta"></pro-alerta>
    <span class="titulo-xsm" v-if="show">
       {{texto}}
      <countdown :time="time" :interval="100" tag="span"
                 :transform="transform"
                 @end="handleCountdownEnd">
        <template slot-scope="props">{{ props.hours }} : {{ props.minutes }} : {{ props.seconds }}</template>
      </countdown>
      </span>
  </div>
</template>

<script>
/* eslint-disable */
  export default {
    props: ['tempo', 'texto'],
    name: "ProTimer",
    data() {
      var now = new Date();
      var newTime = new Date(new Date().getTime() + this.tempo * 60000);

      return {
        info: '',
        cor: 'green',
        mostrar: false,
        show: false,
        counting: false,
        time: newTime - now,
      }
    },
    methods: {
      alerta(text, cor, show) {
        this.info = text
        this.cor = cor
        this.mostrar = show
        var _this = this
        var intervalo = setInterval(function () {
          _this.mostrar = false
          window.location = '/'
          clearInterval(intervalo)
        }, 5000)
      },
      fecharAlerta(fechar) {
        this.mostrar = fechar
      },
      startCountdown: function () {
        var _this = this
        var tempoDeEspera = this.tempo * 60 * 1000;
        var timeout = setTimeout(inativo, tempoDeEspera);

        function actividade(e) {
          clearInterval(timeout);
          timeout = setTimeout(inativo, tempoDeEspera);
          _this.counting = false;
          _this.show = false;
        }

        function inativo() {
          _this.counting = true;
          _this.show = true;
        }

        ['keyup', 'touchmove' in window ? 'touchmove' : 'mousemove', "onwheel" in document.createElement("div") ? "wheel" : document.onmousewheel !== undefined ? "mousewheel" : "DOMMouseScroll"].forEach(function (ev) {
          window.addEventListener(ev, actividade);
        });

      },
      handleCountdownEnd: function () {
        this.counting = false;
        this.alerta('Sua sessão expirou. Favor fazer login novamente.', 'orange', true)
      },
      transform(props) {
        Object.entries(props).forEach(([key, value]) => {
          const digits = value < 10 ? `0${value}` : value;
          props[key] = `${digits}`;
        });
        return props;
      },
    },
    created() {
      this.startCountdown()
    }
  }
</script>

<style scoped>
</style>
