/* eslint-disable */
import Vue from 'vue'
import Vuex from 'vuex'
import geral from './modules/geral'

import configuracao from './modules/configuracoes'
import usuario from './modules/usuarios'
import blog from './modules/blog'
import doenca from './modules/doenca'
import instagram from './modules/instagram'
import banner from './modules/banner2'
import banner_control_modal from './modules/banner_control_modal'
import local_atendimento from './modules/local_atendimento'


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    blog,
    geral,
    banner,
    doenca,
    usuario,
    instagram,
    configuracao,
    local_atendimento,
    banner_control_modal
  }
})
