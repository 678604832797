/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class Doenca {
  constructor () {
    this.doenca_id = uuidV4()
    this.titulo = ''
    this.descricao = ''
    this.texto = ''
    this.ordem = ''
    this.tp_doenca = ''
    this.imagens = []
  }
}
