<template>
    <div>
      <v-footer class="pa-3 profooter" fixed  text-xs-center >
        <v-col
          py-3
          text-xs-center
          cols="12"
        >
          <span>Copyrigth &ensp; &copy;{{ new Date().getFullYear() }} —  <a href="http://www.prodatanet.com.br/">Prodata Informática</a> e Cadastro Ltda. Todos os direitos reservados.</span>
        </v-col>
      </v-footer>
    </div>
</template>

<script>
/* eslint-disable */
    export default {
        name: 'ProFooter'
    }
</script>

<style scoped>

</style>
