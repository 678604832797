/* eslint-disable */
import { API } from '../api'
import { acaoApi } from '../helpers/enums'

export const lista = (url) => {
  return API.get(url)
}

export const processar = (url, objeto, acao) => {
  if (acao === acaoApi.GRAVAR) {
    return API.post(url, objeto)
  } else if (acao === acaoApi.REMOVER) {
    return API.delete(url, { data: objeto })
  }
}
export const pegaLista= (url, data) => {
  return API.get(url, { params: {data: data} })
}
export const pega = (url, id) => {
  return API.get(url + '/' + id)
}
export const autentica = (url, objeto) => {
  return API.post(url, objeto)
}

export const pegaDados = (url, data) => {
  // setToken(sessionStorage.getItem('token'))
  return API.post(url, data)
}
