/* eslint-disable */
import {VMoney} from 'v-money'
import {mask} from 'vue-the-mask'
export default {
  data() {
    return {
      dialog_loading: null,
      listaItens: [],
      erros: [],
      estilo: null,
      show_progresso: false,
      informacao: null,
      value: null,
      precisao_2: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
        allowBlank: false,
        min: Number.MIN_SAFE_INTEGER,
        max: Number.MAX_SAFE_INTEGER
      },
      dialog: {
        show: false,
        title: '',
        message: ''
      },
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        precision: 2,
        masked: false
      },
      errorMessages: '',
      formHasErrors: false,
    }
  },
  directives: {money: VMoney, mask},
  methods: {
    cloneObj(obj) {
      return JSON.parse(JSON.stringify(obj))
    },
    itens(item) {
      this.listaItens = item
    },
    closeDialog(dialog) {
      dialog.close()
    },
    onCancel() {
      this.value = 'Não'
    },
    inverterData(newData) {
      var xvals = ''
      if (this.validarCampo(newData)) {
        xvals = newData.split('-')
        return xvals[2] + '/' + xvals[1] + '/' + xvals[0]
      } else {
        return xvals
      }
    },

    mobileModal(size) {
      var width = window.screen.width
      if (width <= 600) {
        size = '90%'
      } else if (width >= 601 && width <= 900) {
        size = '80%'
      } else if (width >= 901 && width <= 1300) {
        size = '70%'
      }
      return size
    },
    retorno (item, termo, retorno1, retorno2) {
      return item === termo ? retorno1 : retorno2
    },
    validarCampo(campo) {
      if (campo !== undefined && campo !== null && campo !== '') {
        return true
      } else {
        return false
      }
    },
    removerMascara (str) {
      if (this.validarCampo(str)) {
        return str.replace(/[^0-9]+/g, '')
      } else {
        return ''
      }
    },
    removeMascaraMoney (x) {
      if (this.validarCampo(x)) {
        var aux
        x = ""+x;
        if ((x.replace(",", ".") != x )){
          if (x.replace(".", "") != x ){
            aux = x;
            x = x.replace(".", "");
          }
          else {
            aux = x;
          }
          if(x.replace(",", ".") != x){
            x = x.replace(",", ".")
          }else{
            x = aux;
          }
        }
        if (isNaN(parseFloat(x)) ){
          x = 0;
        }else{
          x = parseFloat(x)
        }
        return x;
      } else {
        return ''
      }
    },
    removeMascaraMonetaria (v) {
      var a
      var b
      if (v.length >= 10) {
        a = v.replace('.', '')
        a = a.replace('.', '')
        b = a.replace(',', '.')
      } else {
        a = v.replace('.', '')
        b = a.replace(',', '.')
      }
      return b
    },
    validar () {
      this.formHasErrors = false
      Object.keys(this.form).forEach(f => {
        if (!this.form[f]) {
          this.formHasErrors = true
        }
        this.$refs[f].validate(true)
      })
    },
    getValueByKey (item,key) {
      if (item[key] === undefined) {
        return item
      } else {
        return item[key]
      }
    },

  },
}
