<template>
  <v-icon :color="bloqueado === valor ? 'red' : 'green'">
    {{bloqueado === valor ? 'block' : 'check'}}
  </v-icon>
</template>

<script>
/* eslint-disable */
  export default {
    name: "IsBlocked",
    props: ['bloqueado', 'valor']
  }
</script>
