/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
import {BannerImagem} from "./banner_imagem";
export class Banner {
  constructor () {
    this.banner_id = uuidV4();
    this.efeito= 'fade';
    this.url_video= '';
    this.tela_cheia= false;
    this.tem_imagem= false;
    this.is_video= false;
    this.banners_textos = [];
    this.banner_imagem= new BannerImagem(this.banner_id);
    this.imagens= [];
    this.urls_imagem= [];
  }
}
