/* eslint-disable */
import {lista, processar, pegaLista,pegaDados} from '../../services/GenericAPI'
import {Filtro, Join, Left, Where} from "../../models/filtro/filtro";

import {LocalAtendimentoSCH, getBaseSCH} from "../../search/LocalAtendimentoSCH"
import {EventBus} from '../../helpers/event-bus'
import {LocalAtendimento} from '../../models/local_atendimento'

const url = '/local-atendimento'

const state = {
  all: [],
  filtro: new Filtro(),
  atendimento: new LocalAtendimento(),
}

const getters = {
  listaLocalAtendimento: (state) => {
    return state.all
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  async setAllListLocalAtendimento({ commit }) {
    const list = (await lista(url + '/all')).data
    commit('SET_ALL_LIST', list)
    EventBus.$emit('DOENCAS_ALL_CONCLUIDO', true)
  },

  setFiltro({commit}, perPage) {
     commit('SET_FILTRO', LocalAtendimentoSCH(perPage))
  },
  async setAllLocalAtendimento({commit}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data
      // console.log('list: ', list)
      commit('SET_lOCAL_ATENDIMENTOS', list.data)
      EventBus.$emit('LOCAL_PROCESSADO', true)
    } catch (error) {
      EventBus.$emit('LOCAL_PROCESSADO', false)
    }

  },

  async processarLocalAtendimento({commit}, {atendimento, acao}) {
    try {
      let res = await processar(url, atendimento, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      this.dispatch('local_atendimento/setAllLocalAtendimento')
      EventBus.$emit('PROCESSO_CONCLUIDO', true, true, 'LocalAtendimento')
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data)
      EventBus.$emit('PROCESSO_CONCLUIDO', false, true, 'LocalAtendimento')
    }
  }
}

const mutations = {
  SET_lOCAL_ATENDIMENTOS(state, doencas) {
      state.all = doencas
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
