/* eslint-disable */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuetify from 'vuetify'
import VueEditor from 'vue2-editor'

import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/dist/vuetify.min.css'
import './assets/css/main.scss'
import 'font-awesome/css/font-awesome.css'
import 'vuejs-dialog/dist/vuejs-dialog.min.css'
import VueCropper from 'vue-cropperjs';
import VuejsDialog from 'vuejs-dialog'
import Footer from '../src/components/Footer.vue'
import ProTable from './components/tabela/Tabela.vue'
import ProIsBlocked from './components/IsBlocked.vue'
import ContainerForm from '../src/components/ContainerForm.vue'
import ModalCadastro from '../src/components/ModalCadastro.vue'
import ProAlerta from './components/ProAlerta.vue'
import store from './store/index'
import { sync } from 'vuex-router-sync'

import ProTimer from './components/Temporizador.vue'
import ProCropper from './components/CropperImagem.vue'
import ProCard from './components/ProCard.vue'
import ProTableSimples from './components/tabela/TabelaSimples.vue'
import 'vue2-animate/dist/vue2-animate.min.css'
import 'cropperjs/dist/cropper.css'
import ModalCadastroSmall from '../src/components/ModalCadastroSmall.vue'

// Sempre que o vuetfy for atualizado, deve-se verificar o
// funcionamento do v-currency-field
import currency from 'v-currency-field'
// import 'v-currency-field/dist/index.css'
import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
import VueDragResize from 'vue-drag-resize'
import PForm from "./components/PForm";
Vue.use(currency)
Vue.component('vue-draggable-resizable', VueDraggableResizable)

Vue.component('vue-drag-resize', VueDragResize)
Vue.component('vue-editor', VueEditor)
Vue.component('pro-timer', ProTimer)
Vue.component('vue-cropper', VueCropper)
Vue.component('pro-cropper', ProCropper)
Vue.component('pro-alerta', ProAlerta)
Vue.component('pro-footer', Footer)
Vue.component('pro-container-form', ContainerForm)
Vue.component('pro-card', ProCard)
Vue.component('pro-modal-cadastro', ModalCadastro)
Vue.component('pro-table', ProTable)
Vue.component('pro-table-simples', ProTableSimples)
Vue.component('pro-is-blocked', ProIsBlocked)
Vue.component('pro-modal', ModalCadastroSmall)
Vue.component('p-form', PForm)


Vue.use(Vuetify, { theme: {
  primary: '#1d94ff',
  // primary: '#002359',
  // secondary: '#793bc7',
  secondary: '#0a593b',
  accent: '#82B1FF',
  error: '#FF5252',
  info: '#2196F3',
  success: '#4CAF50',
  warning: '#FFC107'
} })

Vue.use(VuejsDialog, {
  html: true,
  loader: true,
  okText: 'Continuar',
  cancelText: 'Cancelar',
  animation: 'fadeIn',
  type: 'alert'
})

Vue.config.productionTip = false
sync(store, router)

const vuetifyOptions = {
  icons: {
    iconfont: 'mdi'
  }
}

new Vue({
  router,
  store,
  render: h => h(App),
  vuetify: new Vuetify(vuetifyOptions)
}).$mount('#app')
